import store from "../store";
import DcbService from "../services/dcbService";
import UpstreamIntegrationService from "../services/upstreamIntegrationService";
import {triggerPostbackForSuccessfulSubscription} from "./utagDataPost";

export async function handleActionClick(user, next, msisdn) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    const evinaToken = urlParams.get('evinaToken') ?? localStorage.getItem('evinaToken') ?? store.state.evinaToken;
    if (!action) window.location = `${store.state.brandConfig.gameUrl}`;
    if (action === 'accept-tier2-v1' && store.state.brandConfig.revenuePartnerId === undefined) await handleDcbAcceptButtonTier2Version1(user, next, msisdn);
    if (action === 'accept-tier2-v1' && store.state.brandConfig.revenuePartnerId) await handleUpstreamAcceptButtonTier2Version1(user, next);
    else await handleExitButton(evinaToken, user);
}

async function handleExitButton(evinaToken, user) {
    let contentEnabled;
    if (user?.contentBlockedEnabled) contentEnabled = "CBADP"
    else contentEnabled = "UDP"
    await DcbService.subscribeEncryptedUser(store.state.encryptedMsisdn, store.state.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
    window.location = 'https://www.vodacom.co.za/vodacom/shopping/v/add-to-bill';
}

async function handleDcbAcceptButtonTier2Version1(user, next, msisdn) {
    const userMsisdn = user?.msisdn ?? msisdn;
    await executeDoiTwoAndSubscribeUser(userMsisdn, next)
}

async function handleUpstreamAcceptButtonTier2Version1(user, next) {
    await UpstreamIntegrationService.doiTwo(user.msisdn)
    const subResponse = (await UpstreamIntegrationService.subscribeV2(user.msisdn, user.token, store.state.campaignId, store.state.googleClickId, store.state.clickId)).data.result;
    if (subResponse === true) {
        window.location = `https://fantasysoccer.vodacomfantasy.com/?fantasyToken=${user.token}`
    } else return next({path: `/generic-error`});
}

async function executeDoiTwoAndSubscribeUser(userMsisdn, next) {
    const serviceId = localStorage.getItem('serviceId') ?? store.state.serviceId ?? store.state.brandConfig.serviceId;
    await DcbService.doiTwo(userMsisdn, serviceId);
    const subscribeUserResponse = await DcbService.subscribeViaProductId(userMsisdn, parseInt(store.state.campaignId), serviceId, store.state.brandConfig.productId, store.state.googleClickId, store.state.clickId);
    await handleUserSubscribeResponse(userMsisdn, subscribeUserResponse, next);
}

async function handleUserSubscribeResponse(userMsisdn, subscribeUserResponse, next) {
    setUser(subscribeUserResponse);
    const getEncryptedMsisdnResponse = await DcbService.getEncryptedMsisdn(userMsisdn);
    if (subscribeUserResponse.result) {
        triggerPostbackForSuccessfulSubscription();
        const {gameUrl, gameTitle} = store.state.brandConfig;
        const encryptedMsisdn = getEncryptedMsisdnResponse.data.encryptedMsisdn;

        if (gameTitle === 'Quizinc') {
            window.location = `${gameUrl}/optin/service/4070?cid=100920&bc_id=63&acq-channel=app&optin-channel&?e-msisdn=${encryptedMsisdn}`;
        } else {
            window.location = `${gameUrl}/?e-msisdn=${encryptedMsisdn}`;
        }
    } else return next({path: `/generic-error`});
}

function setUser(user) {
    store.commit('setUser', user);
}
