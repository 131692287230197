import store from "../store";
import isMobile from "./deviceDetection";

function addDaysToDateTime(date, n){
    const d = new Date(date);
    d.setDate(d.getDate() + n);
    return d.toLocaleDateString();
}

export function triggerPostbackForSuccessfulLogin() {
    const baseUtagData = getBaseUtagData();
    const loginUtagData = { login_successful: 1 };
    const utagData = Object.assign(baseUtagData, loginUtagData);
    if (utag) utag.view(utagData);
}

export function triggerPostbackForSuccessfulSubscription() {
    const baseUtagData = getBaseUtagData();
    const successfulSubscriptionUtagData = getSuccessfulSubscriptionUtagData();
    const utagData = Object.assign(baseUtagData, successfulSubscriptionUtagData);
    if (utag) utag.view(utagData);
}

function getBaseUtagData() {
    return {
        tealium_event: "view",
        page_name: `${store.state.brandConfig.gameTag}:landing`,
        page_section: `${store.state.brandConfig.gameTag}:landing`,
        page_parent_domain: `${store.state.brandConfig.gameUrl}`,
        page_country: "south africa",
        page_url: `${store.state.brandConfig.gameUrl}`,
        page_locale: "za",
        page_title: document.title,
        page_platform: isMobile() ? 'web-mobile' : 'web-desktop',
        visitor_id_asset_active: `${store.state.user?.subscriptionId}`,
        visitor_login_status: `loggedin`,
        site_version: "1.0",
        site_type: "web"
    }
}

function getSuccessfulSubscriptionUtagData() {
    return {
        event_name: "subscription",
        product_id: [`${store.state.user.serviceId}`],
        product_name: [store.state.brandConfig.gameTitle],
        product_price: [store.state.brandConfig.rawPricePoint],
        product_category: ["Subscription"],
        product_sku: ["12345"],
        product_type: "Mobile",
        visitor_asset_plan_active: [store.state.brandConfig.gameTitle],
        visitor_asset_plan_id_active: `${store.state.user?.subscriptionId}`,
        visitor_asset_subscription_id_active: `${store.state.user?.subscriptionId}`,
        visitor_asset_plan_name_active: [store.state.brandConfig.gameTitle],
        visitor_asset_plan_type_active: "Subscription",
        visitor_asset_plan_brand_active: "games",
        visitor_asset_duration_active: "1 Days",
        visitor_assest_type_active: "free trial",
        visitor_assest_date_end_active: addDaysToDateTime(new Date().toLocaleDateString(), 1),
        visitor_assest_date_start_active: new Date().toLocaleDateString(),
        visitor_addon_name_active: "1 Days",
        visitor_addon_type_active: "free trial",
        visitor_addon_date_end_active: addDaysToDateTime(new Date().toLocaleDateString(), 1),
        visitor_addon_date_start_active: new Date().toLocaleDateString(),
        subscription_completed: 1,
        transaction_id: `${store.state.user?.subscriptionId}`,
        order_revenue: [store.state.brandConfig.rawPricePoint],
        order_completed: 1,
        subscription_type: 'daily'
    }
}
