import store from "../store";

export default function () {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
    const googleClickId = urlParams.get('gclid') ?? urlParams.get('tmt_data');
    const clickId = urlParams.get('clickid') ?? urlParams.get('clickId') ?? urlParams.get('click_id') ?? urlParams.get('ofreqid') ?? urlParams.get('transaction_id');
    const theme = urlParams.get('theme');
    return {
        campaignId: campaignId ?? store.state.campaignId,
        googleClickId: googleClickId ?? store.state.googleClickId,
        clickId: clickId ?? store.state.googleClickId,
        theme: theme ?? store.state.theme,
    }

}
