import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";
import {Buffer} from "buffer";

const headers = {Authorization: `Basic ${environmentConfig.authHeader}`};

export default class DcbService {
    static async getEncryptedMsisdn(msisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                    msisdn
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaProductId(msisdn, productId) {
        try {
            const response = await axios.post(`${environmentConfig.dcbService}/get-user-via-product-id`, {
                    msisdn,
                    productId: productId,
                    externalRef: 1233564,
                }, {headers}
            )
            return response?.data;
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiTwo(msisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/doi-two`, {
                    msisdn,
                    serviceId: serviceId,
                    origination: "Fanclash - Web"
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeViaProductId(msisdn, campaignId, serviceId = "vc-fanclash-quizinc-01", productId, googleClickId, clickId) {
        const response = await axios.post(`${environmentConfig.dcbService}/subscribe-via-product-id`, {
            msisdn: msisdn,
            campaignId: campaignId,
            revenuePartnerId: 34,
            serviceId,
            acquisitionChannel: 'Organic-Web',
            productId: productId,
            googleClickId,
            clickId
        }, {headers});
        return response?.data;
    }

    static async requestOtp(msisdn, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/request-otp`, {
                msisdn: msisdn,
                serviceId: serviceId,
            }, {headers}
        )
    }

    static async validateOtp(msisdn, serviceId, otp) {
        const response = await axios.post(`${environmentConfig.dcbService}/validate-otp`, {
                msisdn: msisdn,
                serviceId: serviceId,
                otp: otp,
            }, {headers}
        )
        return response?.data;
    }

    static async trackImpression(campaignId, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/marketing/track-impression`, {
                msisdn: null,
                campaignId: campaignId,
                acquisitionChannel: 'Organic_Web_Google',
                revenuePartnerId: null,
                onWifi: null,
                serviceId: serviceId
            }, {headers}
        )
    }

    static async getEvinaScript(te, serviceId, msisdn) {
        const response = await axios.post(`${environmentConfig.dcbService}/get-evina-script`, {
                targetElement: te,
                msisdn: msisdn,
                serviceId: serviceId
            }, {headers}
        )
        return {
            script: Buffer.from(response.data.script.toString(), "base64").toString(),
            evinaToken: response.data.token
        };
    }

    static async getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId = "vc-fanclash-quizinc-05") {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-encrypted-msisdn/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: serviceId,
                    externalRef: 1233564,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getOTPServiceOffers(encryptedMsisdn, serviceId = "vc-fanclash-quizinc-05") {
        try {
            const serviceOffersResponse = await axios.post(`${environmentConfig.dcbService}/v2/otp/get-service-offers`, {
                msisdn: encryptedMsisdn,
                serviceId: serviceId,
            }, {headers});
            return serviceOffersResponse?.data;
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeEncryptedUser(encryptedMsisdn, serviceId = "vc-fanclash-quizinc-05", campaignId, evinaToken, contentOverride, googleClickId, clickId) {
        try {
            const serviceOffersResponse = await axios.post(`${environmentConfig.dcbService}/v2/encrypted/subscribe`, {
                encryptedMsisdn: encryptedMsisdn,
                serviceId: serviceId,
                revenuePartnerId: 33,
                campaignId: campaignId,
                acquisitionChannel: 'Organic-Web',
                evinaToken: evinaToken,
                contentOverride: contentOverride,
                googleClickId,
                clickId,
                "externalRef": 12335642
            }, {headers});
            return serviceOffersResponse?.data;
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeOTPUser(encryptedMsisdn, serviceId = "vc-fanclash-quizinc-05", otp, pricepoint, campaignId, evinaToken, contentOverride, googleClickId, clickId) {
        try {
            const serviceOffersResponse = await axios.post(`${environmentConfig.dcbService}/v2/otp/subscribe`, {
                encryptedMsisdn: encryptedMsisdn,
                serviceId: serviceId,
                otp,
                pricepoint,
                revenuePartnerId: 33,
                campaignId: campaignId,
                acquisitionChannel: 'Organic-Web',
                evinaToken: evinaToken,
                contentOverride: contentOverride,
                googleClickId,
                clickId,
                "externalRef": 12335642
            }, {headers});
            return serviceOffersResponse?.data;
        } catch (e) {
            return {authError: true};
        }
    }


    static async getServiceOffers(encryptedMsisdn, serviceId = "vc-fanclash-quizinc-05") {
        try {
            const serviceOffersResponse = await axios.post(`${environmentConfig.dcbService}/get-service-offers/v2`, {
                msisdn: encryptedMsisdn,
                serviceId: serviceId,
            }, {headers});
            return serviceOffersResponse?.data;
        } catch (e) {
            return {authError: true};
        }
    }

    static async getHeaderEnrichedMsisdn() {
        try {
            const response = await axios.get(`${environmentConfig.headerEnrichmentService}`);
            return response?.data;
        } catch (e) {
        }
    }

    static async getEncryptedUserViaSubscriptionId(subscriptionId, serviceId = "vc-fanclash-quizinc-05") {
        try {
            const getUserViaSubscriptionIdResponse = await axios.post(`${environmentConfig.dcbService}/v2/get-user-via-subscription-id`, {
                subscriptionId: subscriptionId,
                serviceId: serviceId,
            }, {headers});
            return getUserViaSubscriptionIdResponse?.data;
        } catch (e) {
            return {authError: true};
        }
    }

    static async decryptMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.dcbService}/decrypt-msisdn`, {
                encryptedMsisdn,
            }, {headers}
        )
    }

}
