import store from "../store";
import {Brands} from "@/brand/brandConfig";
import DcbService from "../services/dcbService";
import UpstreamIntegrationService from "../services/upstreamIntegrationService";
import UtilsService from "../services/utilsService";
import getCampaignParametersFromUrl from "./getCampaignParametersFromUrl";
import {handleActionClick} from "./handleActionClick";
import {triggerPostbackForSuccessfulLogin} from "./utagDataPost";

export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;
    const {productId} = store.state.brandConfig;

    switch (brand) {
        case Brands.VodacomUnlockThree:
        case Brands.VodacomStopClock:
        case Brands.VodacomHeadToHead:
        case Brands.VodacomBeatTheJams:
        case Brands.VodacomQuizInc:
        case Brands.VodacomMasterStream:
        case Brands.VodacomMzansiGames:
            await userAuthenticateInternalEvina(to, from, next, productId);
            break;
        case Brands.VodacomEvinasUnlockThree:
        case Brands.VodacomEvinaBeatTheJams:
        case Brands.VodacomEvinaHeadToHead:
        case Brands.VodacomEvinaQuizInc:
        case Brands.VodacomEvinaStopClock:
        case Brands.VodacomEvinaMasterStream:
            await userAuthVodacomEvina(to, from, next, productId);
            break;
        case Brands.VodacomFantasyFootball:
            await userAuthenticateFantasyFootball(to, from, next);
            break;
        default:
            await userAuthenticateInternalEvina(to, from, next, productId);
            break;
    }
}

async function userAuthenticateInternalEvina(to, from, next, productId) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const eMsisdn = urlParams.get('e-msisdn');
    const campaignParameters = getCampaignParametersFromUrl(urlParams);

    if (token || eMsisdn) {
        if (campaignParameters.campaignId) setCampaignId(campaignParameters.campaignId);
        if (campaignParameters.clickId) setClickId(campaignParameters.clickId);
        if (campaignParameters.googleClickId) setGoogleClickId(campaignParameters.googleClickId);
        if (campaignParameters.theme) setTheme(campaignParameters.theme);
        if (token) return await utilsEncryptedMsisdnAuth(token, next, productId);
        if (eMsisdn) return await dcbEncryptedMsisdnAuth(eMsisdn, next, productId);
    } else if (to.name === 'OnWifiInternalEvina' || to.name === 'TimeoutError' || to.name === 'GenericError') {
        return next();
    }
    return next();
}

async function userAuthenticateFantasyFootball(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const campaignParameters = getCampaignParametersFromUrl(urlParams);

    if (token) {
        if (campaignParameters.campaignId) setCampaignId(campaignParameters.campaignId);
        if (campaignParameters.clickId) setClickId(campaignParameters.clickId);
        if (campaignParameters.googleClickId) setGoogleClickId(campaignParameters.googleClickId);
        if (token) return await upstreamEncryptedMsisdnAuth(token, next);
        //TODO the insufficient funds here will cause problems sending the user on a different journey potentially.
    } else if (to.name === 'OnWifiUpstream' || to.name === 'TimeoutError' || to.name === 'GenericError' || to.name === "InvalidUser" || to.name === "InsufficientFunds" || to.name === "ContentBlock") {
        return next();
    }
    return next();
}

async function userAuthVodacomEvina(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignParameters = getCampaignParametersFromUrl(urlParams);

    if (campaignParameters.campaignId) setCampaignId(campaignParameters.campaignId);
    if (campaignParameters.clickId) setClickId(campaignParameters.clickId);
    if (campaignParameters.googleClickId) setGoogleClickId(campaignParameters.googleClickId);

    if (to.name === 'DoiTwoVodacomEvina' || to.name === 'GenericEvinaError' || to.name === 'TimeoutError' || to.name === 'OnWifiVodacomEvina' || to.name === "InsufficientFunds" || to.name === "ContentBlock" || to.name === "ContentBlockOverride" || to.name === "InvalidUser") {
        return next();
    }

    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const encryptedMsisdn = urlParams.get('encryptedMsisdn') ?? localStorage.getItem('encryptedMsisdn');

    if (encryptedMsisdn && token) {
        return await storeUserDetails(encryptedMsisdn, token, next);
    } else {
        return next({name: 'GenericError'});
    }
}

async function storeUserDetails(encryptedMsisdn, token, next) {
    //change to use get service offers instead.
    //remove token from all calls.
    const userDataResponse = await DcbService.getUserViaEncryptedMsisdn(encryptedMsisdn, token);
    await setEncryptedUserData(userDataResponse, token, encryptedMsisdn)
    return redirectBasedOnEncryptedUserStatus(userDataResponse.data, next);
}

async function setEncryptedUserData(userDataResponse, token = undefined, encryptedMsisdn = undefined) {
    store.commit('setToken', token);
    store.commit('setEncryptedMsisdn', encryptedMsisdn);
    localStorage.setItem('token', token);
    localStorage.setItem('encryptedMsisdn', encryptedMsisdn);
}

async function redirectBasedOnEncryptedUserStatus(user, next) {
    if (user.status === "Active") {
        this.triggerPostbackForSuccessfulLogin();
        window.location = `https://${store.state.brandConfig.gameUrl}?e-msisdn=${user.token}`;
    }
    //TODO need error page for content block
    else if (user.status === 'Content Blocked') return next({name: 'ContentBlock'});
    // else if (user?.contentBlockedEnabled) return next({name: 'ContentBlockOptIn'});
    else return await handleActionClick(user, next);
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

function setClickId(setClickId) {
    store.commit('setClickId', setClickId);
}

function setGoogleClickId(googleClickId) {
    store.commit('setGoogleClickId', googleClickId);
}

function setTheme(theme) {
    store.commit('setTheme', theme);
}

function setMsisdn(msisdn) {
    store.commit('setMsisdn', msisdn);
}

function setUser(user) {
    store.commit('setUser', user);
}

async function utilsEncryptedMsisdnAuth(token, next, productId) {
    const msisdn = (await UtilsService.getMsisdnFromEncryptedMsisdn(token)).data.data.msisdn;
    const user = await DcbService.getUserViaProductId(msisdn, productId);
    setUser(user);
    return await redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, msisdn);
}

async function dcbEncryptedMsisdnAuth(eMsisdn, next, productId) {
    const msisdn = (await DcbService.decryptMsisdn(eMsisdn)).data.rawMsisdn;
    setMsisdn(msisdn);
    const user = await DcbService.getUserViaProductId(msisdn, productId);
    setUser(user);
    return await redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, msisdn);
}

async function upstreamEncryptedMsisdnAuth(token, next) {
    const msisdn = (await UtilsService.getMsisdnFromEncryptedMsisdn(token)).data.data.msisdn;
    const fantasyToken = (await UpstreamIntegrationService.msisdnLogin(msisdn)).data.token;
    const user = (await UpstreamIntegrationService.getUserStatus(fantasyToken)).data
    return await redirectUpstreamUserBasedOnUserStatusMarketingFlow(user, next);
}

async function redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, msisdn) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (action) return await handleActionClick(user, next, msisdn);
    switch (user.status) {
        case "Active":
            if (!store.state.brandConfig.multiTierService || user.serviceId === store.state.brandConfig.serviceId) return await encryptedDcbMsisdnLogin(user);
            break;
        case "Content Blocked":
            return next({path: '/generic-error'});
        case "Interested":
            return next();
        case "Unsubscribed":
            return next();
        default:
            return next();
    }
}

async function redirectUpstreamUserBasedOnUserStatusMarketingFlow(user, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (user.status === "Subscribed") {
        return await tokenUpstreamLogin(user.token);
    }

    if (user.status === 'Content Blocked') {
        return next({path: '/generic-error'});
    }

    if (action) {
        return await handleActionClick(user, next);
    } else {
        window.location = `${store.state.brandConfig.gameUrl}`;
    }
}

async function encryptedDcbMsisdnLogin(user) {
    const encryptedMsisdn = (await DcbService.getEncryptedMsisdn(user.msisdn)).data.encryptedMsisdn;
    const {gameUrl, gameTitle} = store.state.brandConfig;

    triggerPostbackForSuccessfulLogin();
    if (gameTitle === 'Quizinc') {
        window.location = `${gameUrl}/optin/service/4070?cid=100920&bc_id=63&acq-channel=app&optin-channel&?e-msisdn=${encryptedMsisdn}`;
    } else {
        window.location = `${gameUrl}/?e-msisdn=${encryptedMsisdn}`;
    }
}

async function tokenUpstreamLogin(token) {
    window.location = `https://fantasysoccer.vodacomfantasy.com/?fantasyToken=${token}`
}
